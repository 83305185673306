import { Link } from "gatsby"
import * as React from "react"
import Logo from "../assets/logo.png"
import * as style from "../style/_footer.module.scss"


function Footer() {
    return (
        <footer className={style.footerWrap}>
            ©️2022 Processing Community Japan
            <div>
                <span><a href="https://discord.gg/WeNzf4c">Discord</a></span>
                <span><a href="https://twitter.com/PCD_Tokyo">Twitter</a></span>
            </div>
        </footer>
    )
}

export default Footer