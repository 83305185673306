import * as React from "react"
import { Link, graphql, HeadProps } from "gatsby"
import Header from "../components/Header"
import Footer from "../components/Footer"
import * as style from "../style/_about.module.scss"
import Logo from "../assets/logo.png"
import discord from "../assets/discord.svg"
import twitter from "../assets/twitter.svg"
import Seo from "../components/Seo"


type DataProps = {
    site: {
        siteMetadata: {
            title: string
            description: string
        }
    }
}

const AboutPage = ({ data, data: { site } }: PageProps<DataProps>) => {
    return (
        <main>
            <Header></Header>
            <div className={style.contentWrap}>
                <div className={style.imageWrap}>
                    <div className={style.logoWrap}>
                        <Link to="/"><img src={Logo} alt="Processing Community Japan" /></Link>
                    </div>
                </div>
                <div className={style.textWrap}>
                    <h1>About Us</h1>
                    <p>
                        Processing Community Japanは、Processingやp5.jsによるクリエイティブなコーディングを楽しむための情報や場を共有するオンラインコミュニティです。情報発信のほか、もくもく会や勉強会など、各種オンラインイベントの企画など、非営利の有志団体が運営しています。<br /><br />
                        2019年に「Processing Community Day Tokyo 2019」を東京で開催して以来、Processingをはじめとするクリエイティブ・コーディングに関して、ユーザ同士が相互に学び合えるイベントを主にオンラインで不定期に行ってきました。<br /><br />
                        日常的に集まるDiscordでは、初心者から上級者まで思い思いに日々の活動をシェアしたり質問しながら、自分のペースでクリエイティブコーディングを楽しみ、活動しています。Discordに加えて、TwitterアカウントでもProcessingやクリエイティブコーディングを楽しむ上で有益な情報を発信しています。<br /><br />

                        2022年9月、メンバー有志により公式サイト「processing.jp」をスタートさせました。今後は、ユーザ相互の尊重のもと親睦と理解を深め、オンライン・オフラインを問わず、情報交換や展示イベントなど、プログラミングに関わるあらゆる創作活動に寄与することを理念として活動してまいります。<br /><br />
                    </p>
                    <p className={style.textLeft}>運営メンバー一同(ABC順)</p>
                    <div className={style.memberWrap}>
                        <a href="https://twitter.com/ohayoooota">@ohayoooota</a>
                        <a href="https://twitter.com/hina_nkmr">@hina_nkmr</a>
                        <a href="https://twitter.com/masakick">@masakick</a>
                        <a href="https://twitter.com/mmits003">@mmits003</a>
                        <a href="https://twitter.com/nagayama">@nagayama</a>
                        <a href="https://twitter.com/senbaku">@senbaku</a>
                        <a href="https://twitter.com/takawo">@takawo</a>
                    </div>
                    <div className={style.snsWrap}>
                        <span className={style.snsIconWrap}><a href="https://discord.com/invite/WeNzf4c"><img src={discord} alt="" /></a>
                        </span>
                        <span className={style.snsIconWrap}>
                            <a href="https://twitter.com/PCD_Tokyo"><img src={ twitter } alt="" /></a>
                        </span>
                    </div>
                    <div className={style.textLeft}><a  href="https://forms.gle/wGZ9ujYYRcguHxbH7">お問い合わせ</a></div>
                </div>
            </div>
            <Footer></Footer>
        </main>
    )
}

export default AboutPage

export const Head = () => <Seo title='About' />;

export const query = graphql`
query AboutPageQuery{
  site{
    siteMetadata{
      title
      description
      siteUrl
      social{
        twitter
      }
    }
  }
}
`