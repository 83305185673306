import * as React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Fav from '../assets/fav.png';
import OgpImage from '../assets/ogpimage.jpg';

interface Props {
  description?: string;
  title: string;
  ogpImgPath?: string;
}

const SEO:React.FC<Props> = ({ description, title, ogpImgPath }) => {
    const { site } = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                title
                description
                siteUrl
                social {
                  twitter
                }
              }
            }
          }
        `,
      );

    const metaDescription = description || site.siteMetadata.description;
    const defaultTitle = site.siteMetadata?.title;   
    const ogpImg = site.siteMetadata?.siteUrl + (ogpImgPath ?? OgpImage);
    const titleText = title ? `${title} | ${defaultTitle}` : defaultTitle;

    return (
      <>
        <title>{titleText}</title>
        <meta name="description" content={metaDescription} />
        <link rel="icon" href={Fav} />
        <meta property="og:title" content={titleText} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:site_name" content={defaultTitle} />
        <meta property="og:image" content={ogpImg} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={site.siteMetadata?.social?.twitter || ``} />
        <meta name="twitter:title" content={titleText} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content={ogpImg}></meta>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
      </>
    )
  }
  
export default SEO;